<template>
	<section class="media-detail-container pp" v-if="showsDetail">

		<div class="media-played-container" v-if=" videoUrlType && videoUrlType != 'unknown'" :style="{ backgroundImage: backgroundImage }">
			<div class="media-info-cover"></div>
			<video v-if="videoUrlType == 'inner_video_url' ||  videoUrlType == 'outer_video_url'" :src="videoUrl" controls></video>
			<!-- <iframe v-if="videoUrlType == 'outer_h5_url'" :src="videoUrl" frameborder="0"></iframe> -->
			<iframe v-if="videoUrlType == 'outer_h5_url'" :src="videoUrl" width="100%" height="500px" frameborder="0" scrolling="no" allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>
			<!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/KXhm9oiHSOw?si=yIZy90L8NrxJbWMb" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> -->
		</div>

		<div class="media-info-container">
			<div class="media-poster">
				<img :src="`${$store.state.home.base_url}${$store.state.home.poster_size[3]}${showsDetail.poster_path}`" class="media-poster-img" alt="" />
			</div>
			<div class="media-info-row">
				<div class="media-title">{{ showsDetail.title }}</div>
				<div class="media-update-info-row flex-row just-start">
					<div class="media-upload-date">
						{{ showsDetail.release_date }}
					</div>
					<div class="media-slish">·</div>
					<div class="media-type"><span v-for="(genres,j) in showsDetail.genres" :key="j">{{ genres.name }} </span></div>
					<div class="media-slish">·</div>
					<div class="media-duration">{{convertToHoursAndMinutes(showsDetail.runtime)}}</div>
				</div>
				<div class="media-score-row flex-row just-start align-center">
					<div class="star-info-row">
						<div class="stars-row">
							<div class="full-star star-item" v-for="i in fullStars" :key="`full_${i}`"></div>
							<div class="em-star star-item" v-for="i in emptyStars" :key="i"></div>
						</div>
						<div class="star-num">{{ vote_point }}</div>
					</div>
					<!-- <div class="play-trailer">
						<img src="@/assets/home/PLAY@2x.png" class="play-btn" alt="" />
						Play Trailer
					</div> -->
				</div>

				<div class="media-overview-row">
					<div class="media-overview-title">Overview</div>
					<div class="media-overview-content" v-html="showsDetail.overview"></div>
				</div>

				<!-- <div class="media-staff-row">  //演职人员数据时总体 不能区分导演等
					<div class="media-staff-item flex-col just-start" v-for="i in 5" :key="i">
						<div class="staff-name">Seth MacFarlane</div>
						<div class="staff-posit">Creator</div>
					</div>
				</div> -->
			</div>
		</div>

		<div class="cast-container">
			<div class="white-shadow" ref="castShadow"></div>
			<div class="cast-title">Top Billed Cast</div>
			<div class="cast-row" ref="cast" @scroll="handleScroll('cast','castShadow')">
				<div v-for="(caster,k) in castList" :key="`cast_${k}`">
					<div class="cast-item flex-col just-start" @click="gotoCast(caster.id)" v-if="caster.profile_path">
						<img :src="`${$store.state.home.base_url}${$store.state.home.profile_size[2]}${caster.profile_path}`" class="cast-avatar" alt="" />
						<div class="cast-name">{{ caster.name}}</div>
						<div class="cast-more-info">{{ caster.character }}</div>
					</div>
				</div>
			</div>
		</div>

		<div class="stage-photo-row">
			<div class="white-shadow"></div>
			<div class="stage-photo-title-row" ref="stagePhotoShadow">
				<div class="stage-photo-title">What’s Poplar</div>
				<div class="stage-photo-sub-nav-row">
					<div class="stage-photo-sub-nav-item active">Streaming</div>
					<div class="stage-photo-sub-nav-item">On TV</div>
					<div class="stage-photo-sub-nav-item">For Rent</div>
					<div class="stage-photo-sub-nav-item">In Theaters</div>
				</div>
			</div>
			<div class="photos-row" ref="stagePhoto" @scroll="handleScroll('stagePhoto','stagePhotoShadow')">
				<div class="backdrop" v-for="(backdrop,l) in backdropsList" :key="l">
					<img :src="`${$store.state.home.base_url}${$store.state.home.poster_size[3]}${backdrop.file_path}`" alt="" />
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import "@/css/home/pc/media-detail.scss";

import { mapState } from "vuex";

export default {
	data() {
		return {
			backgroundImage: "",
			showShadow: {
				cast: true,
			},
			showsDetail: null,
			vote_point: 0,
			creditList: null,
			castList: null,
			backdropsList: null,
			media_type: null,
			videoUrlType: null,
			videoUrl: null,
		};
	},
	computed: {
		...mapState({
			targetValue: (state) => state.homedata,
		}),
	},
	created() {
		console.log(this.$route.query);
		this.media_type = this.$route.query.media_type;
		if (this.$store.state.homedata == "success") {
			this.handleDetail();
		}
		this.$store.watch(
			(state, getters) => state.homedata,
			(newVal, oldVal) => {
				// 在这里处理状态变化
				console.log("targetValue 已经设置为", newVal);
				this.handleDetail();
			}
		);
	},
	mounted() {},
	computed: {
		fullStars() {
			return Math.floor(Math.floor(this.vote_point) / 2);
		},
		emptyStars() {
			return 5 - this.fullStars;
		},
	},

	methods: {
		handleDetail() {
			this.getDetail(this.$route.query.poster_id);
			this.getVideoSource(this.$route.query.poster_id);
			this.getImages(this.$route.query.poster_id);
			this.getCredit(this.$route.query.poster_id);
		},
		handleScroll(refs, changClass) {
			const father = this.$refs[refs];
			const change = this.$refs[changClass];
			if (
				father.scrollLeft + father.clientWidth >=
				father.scrollWidth - 10
			) {
				change.classList.add("end");
			} else {
				change.classList.remove("end");
			}
		},
		convertToHoursAndMinutes(minutes) {
			var hours = Math.floor(minutes / 60);
			var remainingMinutes = minutes % 60;
			return hours + "h " + remainingMinutes + "m";
		},
		gotoCast(params) {
			this.$router.push({
				name: "cast",
				query: {
					cast_id: params,
				},
			});
		},
		getDetail(id) {
			const options = {
				method: "GET",
				headers: {
					accept: "application/json",
					Authorization:
						"Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyYzMzNjE2YTRjODNiN2Y2OTgyNDI3Njg5NjhhMGY3MyIsInN1YiI6IjY0NjRhYTUwOWYzN2IwMDE1Y2MzODdlYyIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ._XwSWCopAiYWATtv8Vl5w7KyEBJ25mpQf_bnd4Dyhpg",
				},
			};

			fetch(
				this.media_type == "tv"
					? `https://api.themoviedb.org/3/tv/${id}?language=en-US`
					: `https://api.themoviedb.org/3/movie/${id}?language=en-US`,
				options
			)
				.then((response) => response.json())
				.then((response) => {
					console.log(response);
					this.showsDetail = response;
					console.log(
						`${this.$store.state.home.base_url}${this.$store.state.home.poster_size[3]}${response.poster_path}`
					);

					this.backgroundImage = `url('${this.$store.state.home.base_url}${this.$store.state.home.poster_size[3]}${response.poster_path}')`;

					this.vote_point =
						Math.trunc(response.vote_average * 10) / 10;
				})
				.catch((err) => console.error(err));
		},
		getVideoSource(id) {
			const options = {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					tmdbId: id,
					videoCategory: this.media_type == "tv" ? "tv" : "movie",
				}),
			};
			console.log(options);

			fetch(`https://api.aibrowser.me/resource/query`, options)
				.then((response) => response.json())
				.then((response) => {
					console.log(response);
					const rep = response.data;
					this.videoUrlType = rep.videoUrlType;
					this.videoUrl =
						this.media_type == "tv"
							? rep.embedTvUrl
							: rep.movieUrl
							? rep.movieUrl
							: rep.embedMovieUrl;
				})
				.catch((err) => console.error(err));
		},
		getCredit(id) {
			const options = {
				method: "GET",
				headers: {
					accept: "application/json",
					Authorization:
						"Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyYzMzNjE2YTRjODNiN2Y2OTgyNDI3Njg5NjhhMGY3MyIsInN1YiI6IjY0NjRhYTUwOWYzN2IwMDE1Y2MzODdlYyIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ._XwSWCopAiYWATtv8Vl5w7KyEBJ25mpQf_bnd4Dyhpg",
				},
			};

			fetch(
				this.media_type == "tv"
					? `https://api.themoviedb.org/3/tv/${id}/credits?language=en-US`
					: `https://api.themoviedb.org/3/movie/${id}/credits?language=en-US`,
				options
			)
				.then((response) => response.json())
				.then((response) => {
					console.log(response);
					this.castList = response.cast;
					this.crewList = response.crew;

					var crewListNew = response.crew.slice(0, 5);
					console.log(crewListNew);
					// const directerObj = this.crewList.find(obj => obj.job === "Director");
					const directerObjs = this.crewList.filter(
						(obj) => obj.job === "Director"
					);
					console.log(directerObjs);
				})
				.catch((err) => console.error(err));
		},
		getImages(id) {
			const options = {
				method: "GET",
				headers: {
					accept: "application/json",
					Authorization:
						"Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyYzMzNjE2YTRjODNiN2Y2OTgyNDI3Njg5NjhhMGY3MyIsInN1YiI6IjY0NjRhYTUwOWYzN2IwMDE1Y2MzODdlYyIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ._XwSWCopAiYWATtv8Vl5w7KyEBJ25mpQf_bnd4Dyhpg",
				},
			};

			fetch(
				this.media_type == "tv"
					? `https://api.themoviedb.org/3/tv/${id}/images`
					: `https://api.themoviedb.org/3/movie/${id}/images`,
				options
			)
				.then((response) => response.json())
				.then((response) => {
					console.log(response);
					this.backdropsList = response.backdrops;
				})
				.catch((err) => console.error(err));
		},
	},
};
</script>
